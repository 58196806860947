import countdown from '../../../utils/countdown'

function validateCountdown(countdownEnd) {
  if (!countdownEnd) return false

  const currentDateTime = new Date()
  const countdownEndTime = new Date(countdownEnd)

  if (countdownEndTime >= currentDateTime) return true
}

export function showCountdown(blockSelector) {
  if (!blockSelector) return

  const block = document.querySelector(blockSelector)

  if (!block) return

  const countdowns = block.querySelectorAll('.js-countdown')

  if (!countdowns.length) return

  let countdownsInitialized = false

  countdowns.forEach(element => {
    const countdownIsValid = validateCountdown(element.dataset.countdown)
    if (!countdownsInitialized && countdownIsValid) {
      countdown(1000, 9999)
      countdownsInitialized = true
    } else if (!countdownIsValid) {
      element.remove()
    }
  })
}
