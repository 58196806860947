$(document).on('change', '.claim-form [name="[have_booking]"]', function () {
  let $el = $(this);
  let $booking_option = $('.booking-option');

  $booking_option.hide();
  $booking_option.find('input, select').each(function () {
    $(this).attr('data-parsley-required', 'false');
  });

  $('#' + $el.val()).show();
  $('#' + $el.val() + ' input, #' + $el.val() + ' select').each(function () {
    $(this).attr('data-parsley-required', 'true');
  });
})
  .on('change', '#hotel_country_id', function () {
    let country_id = $(this).val();
    let $hotel = $('#hotel_id');
    $hotel.find('option').show().not('[data-country-id="' + country_id + '"]')
      .hide();
    $hotel.val('').trigger('change').trigger('formikation.update');
  })
  .on('change', '#hotel_id', function () {
    let hotel_id = $(this).val();
    $('#hotel_room_id').find('option').show()
      .not('[data-hotel-id="' + hotel_id + '"]')
      .hide();
    $('#board_id').find('option').hide()
      .filter(function () {
        return $.inArray(parseInt(hotel_id, 10), $(this).data('hotel-ids')) > -1;
      })
      .show();
    $('#hotel_room_id, #hoard_id').val('').trigger('change')
      .trigger('formikation.update');
  })
  .on('change', '#hotel_room_id', function () {
    let $opt_selected = $(this).find('option:selected');
    let max_occupancy = parseInt($opt_selected.attr('data-max-occupancy'), 10);
    let max_children = parseInt($opt_selected.attr('data-max-children'), 10);
    let $adults = $('#adults');
    let $children = $('#children');

    $adults.find('option').show().filter(function () {
      return parseInt($(this).val(), 10) > max_occupancy;
    })
      .hide();

    let adults_val = max_occupancy ? Math.min(2, max_occupancy) : 2;
    $adults.val(adults_val).trigger('change').trigger('formikation.update');

    if (max_children > 0) {
      if ($children.val() > max_children) {
        $children.val(max_children).trigger('change').trigger('formikation.update');
      }
      $children.prop('disabled', false).find('option').show()
        .filter(function () {
          return parseInt($(this).val(), 10) > max_children;
        })
        .hide();
    } else {
      $children.val('0').trigger('change').trigger('formikation.update');
      $children.prop('disabled', true);
    }
  });
