import { getLazyData } from '../../utils/get_lazy_data';

if($("#divFBwidget").length){
  let lang = document.documentElement.getAttribute('data-js-lang') == 'es' ? '' : '/' + document.documentElement.getAttribute('data-js-lang');
  let url = lang+"/social-login/widget/";
  getLazyData(url, function (response) {
    if(response.length > 0){
      $("#divFBwidget").append(response);
      $("#x_recommend").attr("style","");
      $("#x_banner").attr("style","");
      $(".facebook-summary-widget-block").removeClass("hidden");
    }
  });
}
