let DEFAULT_CURRENCY = 'EUR';
let actual_currency = $.cookie('currency') !== undefined ? $.cookie('currency') : DEFAULT_CURRENCY;

$(document).on('change', '.currency-agency-selector select', function () {
  let $el = $(this);
  let currency = $el.parents('.currency-agency-form').find('.fk-select-label').text();

  if (currency === 'DEF') currency = DEFAULT_CURRENCY;

  let $general_selector = $el.parents('.reserve-index-list, .quote-index-list');

  $general_selector.find('.data.active').removeClass('active');
  $general_selector.find('.data-' + currency).addClass('active');
  if ($general_selector.find('.data.default-currency.active').length) {
    $general_selector.find('.warning').addClass('hidden');
  } else {
    $general_selector.find('.warning').removeClass('hidden');
  }
});

$(document).on('change', '.currency-selector select', function () {
  if (!$('.reserve-index-list, .quote-index-list').length) return; // No se puede poner fuera porque las reservas tardan en cargar

  let $el = $(this);
  let currency = $el.parents('.currency-form').find('.fk-select-label').text();
  let $agency_select = $('.currency-agency-selector select');

  currency = currency !== '€/$' ? currency : DEFAULT_CURRENCY;

  if (actual_currency !== currency && !$agency_select.find('option[value=' + currency + ']').length) {
    $agency_select.find('option[value=' + actual_currency + ']').remove();
    $agency_select.append($('<option>', {
      value: currency,
      text:  currency
    }));
  }
  $agency_select.val(currency).change();
  actual_currency = currency;
  $('.reserve-index-list, .quote-index-list').find('.data.active').removeClass('active');
  $('.reserve-index-list, .quote-index-list').find('.data-' + currency).addClass('active');
  $('.reserve-index-list, .quote-index-list').each(function () {
    let $this = $(this);
    if ($this.find('.data.default-currency.active').length) {
      $this.find('.warning').addClass('hidden');
    } else {
      $this.find('.warning').removeClass('hidden');
    }
  });
  let $export_css_link = $("#export-csv-link")
  let searchParams = new URLSearchParams($export_css_link.attr("href"));
  searchParams.set('currency_code', currency);
  $export_css_link.attr("href", decodeURIComponent(searchParams.toString()));
});
