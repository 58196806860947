$(document)
  .on('click', '.show-password', function (event) {
    event.preventDefault()
    const $this = $(this)
    const $input = $($this.data('element'))
    const text = $($this).attr('data-label-toogle-text')
    const label = $($this).attr('aria-label')

    $this.toggleClass('active')
    if ($this.hasClass('active')) {
      $input.attr('type', 'text')
    } else {
      $input.attr('type', 'password')
    }

    if (text && label) {
      $($this).attr('data-label-toogle-text', label)
      $($this).attr('aria-label', text)
    }
  })
  .on('click', '.show-password-external', function (event) {
    event.preventDefault()
    const $this = $(this)
    const $input = $($this.data('element'))
    $this.toggleClass('active')
    $input.toggleClass('active')
    if ($input.hasClass('active')) {
      $input.attr('type', 'text')
    } else {
      $input.attr('type', 'password')
    }
  })
  .on('keyup', '.password-row input', function (event) {
    const $this = $(event.target)

    if ($this.hasClass('without-errors')) return

    const $pass_row = $this.closest('.password-row')
    let $parsley_errors
    const $err_new_list = $this.hasClass('out-errors') ? $($this.data('out-errors-id')) : $pass_row.find('.validation-errors')
    let group_to_validate

    group_to_validate = typeof $pass_row.data('group-to-validate') === 'undefined' ? 'password' : $pass_row.data('group-to-validate')

    $err_new_list.removeClass('hidden')

    // retrieve parsley error messages to copy later
    $pass_row.closest('form').parsley().validate(group_to_validate)
    $parsley_errors = $pass_row.find('.parsley-errors-list')

    // iterate over our custom error list to match parsley errors
    // in order to apply validated message class
    setTimeout(function () {
      $.each($err_new_list.find('li'), function () {
        const $that = $(this)
        const elClass = $that.attr('class').split(' ')[0]
        if ($parsley_errors.find('li').length == 1 && $parsley_errors.find('li').attr('class') == 'parsley-required') {
          $that.removeClass('validated')
        } else if ($parsley_errors.find(`.${elClass}`).length === 0) {
          $that.addClass('validated')
        } else {
          $that.removeClass('validated')
        }
      })
    }, 200)
  })

$().fancybox({
  selector: 'a.reset-password',
  type: 'ajax',
  beforeClose() {
    if ($('.date-picker-wrapper').is(':visible')) {
      $('.date-picker-wrapper').hide()
    }
  },
  afterShow() {
    const $close = $(this.$smallBtn[0])
    const $content = $('.reset-password-wrapper')
    if ($content.length) {
      // When open fancybox of reset password, we focus on content and put close button at first place
      $($content).attr('tabindex', '-1')
      $($content).trigger('focus')
      if ($close.length) {
        $close.insertBefore($content)
      }
    }
  },
})
