import { FILTER_OFFER_EVENT } from '../../blocks/hotels_info_with_filtering_packed/hotels_info_with_filtering.constants'
import Modal from '../../shared/modal/modal'

import { initializeUtagEvents } from './offer_modal.analytics'

function initializeShowHotelsButton(button) {
  button.addEventListener('click', event => {
    Modal.close()
    // this timeout is for user experience
    setTimeout(() => {
      const offerId = event.target.dataset.offerId
      const customEvent = new CustomEvent(FILTER_OFFER_EVENT, { detail: { offerId } })
      document.dispatchEvent(customEvent)
    }, 500)
  })
}

export function initModal(modalSelector) {
  const modalContent = document.querySelector(modalSelector)

  if (modalContent.classList.contains('initialized')) return

  const showHotelsButton = modalContent.querySelector('.js-offer-show-hotels')

  if (showHotelsButton) {
    initializeShowHotelsButton(showHotelsButton)
  }

  initializeUtagEvents(modalSelector)

  modalContent.classList.add('initialized')
}
