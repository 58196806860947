$(function () {
  if (IB.currentDevice === 'desktop') { // in desktop or small desktop
    let wait_time = 0;

    $(document).on('mouseenter', '.pro-link', function () {
      let $el = $(this);
      wait_time = 0;
      $el.find('.pro-letter').each(function (index, el) {
        setTimeout(function () {$(el).addClass('active');}, wait_time);
        wait_time += 4;
      });
    }).on('mouseleave', '.pro-link', function () {
      let $el = $(this);
      wait_time = 100;
      $($el.find('.pro-letter').get().reverse()).each(function (index, el) {
        setTimeout(function () {$(el).removeClass('active');}, wait_time);
        wait_time += 4;
      });
    });
  }
});
