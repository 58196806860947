import on from '../../utils/events_delegation'

on({
  eventName: 'click',
  selector: `.js-show-call-me-back-modal`,
  handler(e) {
    e.preventDefault()
    try {
      window.lanzo_cmb()
    } catch (err) {}
  },
})
