import { sendUtagEvent } from '../../core/analytics/utag_events'
import { getCleanedString } from '../../utils/utilities'

export function initializeUtagEvents(eventLabel) {
  sendUtagEvent({
    data: {
      event_name: 'pre_lead',
      event_cat: 'lead',
      event_act: 'pre lead',
      event_lbl: getCleanedString(eventLabel),
      event_purpose: 'loyalty',
      event_structure: 'impression',
    },
  })
}
