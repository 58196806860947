import './footer_sticky.scss';


$(function () {
  let $block = $('#footer-sticky');

  // intialize swiper in mobile
  if (!$block.length) return;

  $(document).on('click', '#footer-sticky [data-action]', function (e) {
    $block.toggleClass('collapsed');
    $block.hasClass('collapsed') ? $('.js-hotel-comparison-link').attr('tabindex', '-1').focus() : $block.attr('tabindex', '-1').focus();
    $block.find('.actions .action a').attr('aria-expanded', !$block.hasClass('collapsed'));
  });
});
