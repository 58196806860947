let $register_form = $('.b-register-form');
let halfs = [0, 1, 5, 6];
let thirds = [2, 3, 4];
let only = [2];
$register_form.find('.register .fz-row').each(function (i) {
  if (halfs.indexOf(i) != -1) $(this).addClass('half');
  if (thirds.indexOf(i) != -1) $(this).addClass('third');
  if (only.indexOf(i) != -1) $(this).addClass('only');
});
$register_form.find('.textual-content').remove();
// para añadir cuando esten hechas las RRSS $('.row.actions').append('<a class="switch-links" id="linkto_rrss">¿Prefieres registrarte con tus redes sociales?</a>');
$register_form.find('.block-form').addClass('show visible');

$(document).on('click', '.b-register-form .switch-links', function () {
  let blocks = { hiddenBlock: '.rrss-form', visibleBlock: '.block-form' };
  if ($(this).attr('id') === 'linkto_rrss') {
    let aux = blocks.hiddenBlock;
    blocks.hiddenBlock = blocks.visibleBlock;
    blocks.visibleBlock = aux;
  }
  $(blocks.hiddenBlock).removeClass('show visible');
  $(blocks.visibleBlock).addClass('show');
  setTimeout(function () {
    $(blocks.visibleBlock).addClass('visible');
  }, 100);
});


$(document).on('click', 'a[data-link="profiling"]', function (e) {
  $('[data-link="profiling-anchor"]').each(function () {
    $(this).show('slow');
  });
});

$(document).on('click', '#link-form-here', function (e) {
  var noSpam = document.querySelector('.content-spam');
  noSpam.classList.toggle('spam');
});
