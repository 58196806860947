export function getMobileOperatingSystem() {
  // Credit: Ryan kulp @ https://www.quora.com/Has-anyone-successfully-preset-body-copy-within-a-tap-to-SMS-link/answer/Ryan-Kulp
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (userAgent.match(/iPhone/i) || userAgent.match(/iPod/i) || userAgent.match(/iPad/i)) return 'ios'

  if (userAgent.match(/Android/i)) return 'android'

  return 'desktop'
}
