import { sendUtagEvent } from '../../core/analytics/utag_events'

export function initializeUtagEvents(buttons) {
  /* Click on FH awards module */
  buttons?.forEach(btn => {
    btn.addEventListener('click', () => {
      sendUtagEvent({
        data: {
          event_name: 'hotel_awards',
          event_cat: 'hotel information',
          event_act: 'see hotel info',
          event_lbl: 'awards',
          event_purpose: 'inspiration',
          event_structure: 'body',
        },
      })
    })
  })
}
