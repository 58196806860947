import { sendUtagEvent } from '../../core/analytics/utag_events'
import on from '../../utils/events_delegation'

export function initializeUtagEvents() {
  const megabannerSlider = document.querySelector('.js-home-megabanner-slider')
  const megabannerItems = megabannerSlider.querySelectorAll('.js-home-megabanner-item:not(.swiper-slide-duplicate)')
  const emptyPromoName = 'empty promotion'
  const emptyPromoId = 'empty-promotion'

  const utagData = {
    enh_action: 'impression list',
    event_name: 'view promotion',
    event_cat: 'ecommerce',
    event_act: 'promotion impression list',
    event_lbl: '',
    non_interaction: '1',
    event_purpose: 'booking',
    event_structure: 'impression',
    promotion_id: [],
    promotion_name: [],
    promotion_creative: ['home|home|video with fastbooking'],
    promotion_position: [],
  }

  megabannerItems.forEach((item, index) => {
    // Corporative
    if (item.classList.contains('js-corporative-banner')) {
      utagData.promotion_id.push('wave-of-change')
      utagData.promotion_name.push('wave of change')
      utagData.promotion_creative.push('home|home|video with fastbooking')
      utagData.promotion_position.push((index + 1).toString())
    } else if (item.classList.contains('js-last-searches-slide')) {
      // Last Searches
      const dataHotelName = item.querySelector('[data-hotel-name]')
      const promotionName = dataHotelName.dataset.hotelName.toLowerCase()
      const promotionId = promotionName.replaceAll(' ', '-')
      utagData.promotion_id.push(promotionId)
      utagData.promotion_name.push(promotionName)
      utagData.promotion_creative.push('home|home|video with fastbooking')
      utagData.promotion_position.push((index + 1).toString())
    } else {
      // Items Slides
      const dataPromoName = item.querySelector('[data-promotion-name]')
      const dataPromoId = item.querySelector('[data-promotion-id]')
      const promoName = dataPromoName?.dataset.promotionName.toLowerCase()
      const promoIdValue = dataPromoId?.dataset.promotionId.toLowerCase()
      const promoId = promoIdValue?.replaceAll(' ', '-')
      utagData.promotion_name.push(promoName || emptyPromoName)
      utagData.promotion_id.push(promoId || emptyPromoId)
      utagData.promotion_creative.push('home|home|video with fastbooking')
      utagData.promotion_position.push((index + 1).toString())
    }
  })

  sendUtagEvent({ data: utagData })

  // Event for CTA Buttons
  on({
    eventName: 'click',
    selector: '.utag-home-megabanner-cta',
    handler() {
      const utag_data = {
        enh_action: 'promo_click',
        event_name: 'select_promotion',
        event_cat: 'ecommerce',
        event_act: 'promo click',
        event_purpose: 'booking',
        event_structure: 'header',
        event_lbl: '',
        promotion_id: [],
        promotion_name: [],
        promotion_creative: ['home|home|video with fastbooking'],
        promotion_position: [],
      }

      const promoClickName = this.dataset.promotionName.toLowerCase()
      const promoClickId = this.dataset.promotionId.toLowerCase().replaceAll(' ', '-')

      let slideIndex = this.closest('.js-slide-element')?.dataset.swiperSlideIndex
      slideIndex = slideIndex ? Number(slideIndex) : 0

      utag_data.promotion_name.push(promoClickName || emptyPromoName)
      utag_data.promotion_id.push(promoClickId || emptyPromoId)
      utag_data.promotion_creative.push('home|home|video with fastbooking')
      utag_data.promotion_position.push(slideIndex + 1).toString()

      sendUtagEvent({ data: utag_data })
    },
  })
}
