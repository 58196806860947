import { getPrefix } from '../../core/utils/locale.js'
import { deleteCookies, readCookie } from '../../utils/cookie_manager'
import { getLazyData } from '../../utils/get_lazy_data'
import { openSessionFancybox } from '../../utils/ib_session_fancybox'

import { initializeUtagEvents } from './horizons_postregister_lightbox.analytics.js'

function cleanSymbols(value) {
  if (/[+%&]/.test(value)) {
    value = value.replace(/[+%&]/g, ' ')
  }
  return value
}

const prelead_type = cleanSymbols(readCookie('prelead_type'))

const openPostregistrationForm = function postregistration_form() {
  try {
    if (prelead_type && prelead_type !== null) {
      initializeUtagEvents(prelead_type)
    }

    const metadata = readCookie('user_metadata')
    console.log(`metadata=${metadata}`)
    const metadataAsObject = JSON.parse(decodeURIComponent(metadata))
    let metadataAsUrl = ''
    for (var key in metadataAsObject) {
      metadataAsUrl += `&${key}=${metadataAsObject[key]}`
    }
    const URL_LIGHTBOX = `${getPrefix()}/ajax_contents/lightbox_for_block/?lightbox_type=registration&postregistration=true${metadataAsUrl}`
    getLazyData(URL_LIGHTBOX, function (fancyboxHtml) {
      openSessionFancybox(fancyboxHtml, '.register form', {}, true)

      // Delete cookie when user cancels post-registration window
      const cancelPostRegister = document.querySelector('.cancel-register')
      cancelPostRegister?.addEventListener('click', () => {
        deleteCookies(['prelead_type'])
      })
    })
  } catch (e) {
    console.log(`Error horizons_postregister_lightbox.js${e}`)
  }
}
const auth0_postregister_form = readCookie('auth0_postregister_form')
console.log(`auth0_postregister_form=${auth0_postregister_form}`)
const user_metadata = readCookie('user_metadata')

if (auth0_postregister_form === 'true' && user_metadata !== null) {
  openPostregistrationForm()
}

export default openPostregistrationForm
