import { initBottomLinks } from '../bottom_links/bottom_links';
import { initDashboardBookingRow } from '../dashboard_booking_row/dashboard_booking_row';

export function init() {
  let $nextBooking = $('.next-bookings');

  if ($nextBooking.length > 0) {
    $.ajax({
      url:     $nextBooking.data('href'),
      success: function (data) {
        $nextBooking.html(data);
        initBooking();
      }
    });
  } else {
    initBooking();
  }
}

function initBooking() {
  initBottomLinks();
  initDashboardBookingRow();
  if (IB.pwaBanner !== undefined) IB.pwaBanner.init();
}

init();
