/**
 * Force Call Center Lightbox
 *
 * When cancel online bookings option is enabled in CMS,
 * we override the following links:
 * - Manage bookings in Vue header (already rendered with the FLAG_CLASS)
 * - Manage bookings in footer (a.manage-bookings)
 * - Points exchange in MIB (.bottom-links-container both .exchange-points and .cancel links)
 *
 * with a XHR petition that returns a lightbox, very much alike the contact lightbox.
 * Not overriding directly without the class to make it clear that the click
 * event is being overriden by this enabled mode in the CMS.
 *
 **/
import { getLazyData } from '../../utils/get_lazy_data';
import { readCookie } from '../../utils/cookie_manager';
import { getPrefix } from '../../core/utils/locale.js';

const ONLINE_BOOKINGS_DISABLED = document.getElementsByTagName('body')[0].classList.contains('js-force-callcenter-lightbox');
if (ONLINE_BOOKINGS_DISABLED) {
  let htmlTag = document.documentElement;
  let device = htmlTag.dataset.deviceType;

  let classes = '.js-force-callcenter-lightbox-link, .manage-my-booking, .bottom-links-contaniner .cancel, .bottom-links-contaniner .modify, .bottom-links-contaniner .modify-booking, .bottom-links-contaniner .exchange-points';
  if (device !== 'mobile') {
    classes += ', a.manage-bookings';
  } else {
    classes += ', .menu_item:not(.title) a.manage-bookings, .bottom-links-mobile .cancel, .bottom-links-mobile .modify, .bottom-links-mobile .modify-booking, .bottom-links-mobile .exchange-points, .points-bottom .exchange-points-link';
  }

  $(document).on('click', `${classes}`, function (e) {
    e.preventDefault();
    let htmlTag = document.documentElement;
    let country = readCookie('x-country');
    let marketId = htmlTag.dataset.marketId;
    let site = IB.is_in_microsite ? htmlTag.dataset.inMicrosite : 'iberostar';
    let url = `${getPrefix()}/ajax_contents/lightbox_callcenter/?country=${country}&market_id=${marketId}&site=${site}&force=${force_cc}`;

    getLazyData(url, function (lightboxHtml) {
      $.fancybox.open(lightboxHtml);
    });
  });
}
