import { setCookie } from '../../utils/cookie_manager';

$(function () {
    const COOKIE_NAME = 'next_opening_hotels_popup_displayed';
    const POPUP_SELECTOR = '#next-opening-info';
    let nextOpeningHotelsWithPopupDisplayed = JSON.parse($.cookie(COOKIE_NAME) || '[]');
    let hotelCode;
    if (
      $(POPUP_SELECTOR).length > 0 &&
      (hotelCode = $(POPUP_SELECTOR).data('hotel-code')) &&
      nextOpeningHotelsWithPopupDisplayed.indexOf(hotelCode) < 0
    ) {
      showPopup($(POPUP_SELECTOR));
      nextOpeningHotelsWithPopupDisplayed.push(hotelCode);
      setCookie(COOKIE_NAME, JSON.stringify(nextOpeningHotelsWithPopupDisplayed), 30);
    }  
});

function showPopup($element) {
  let is_mobile = IB.currentDevice === 'mobile';
  $.fancybox.open($element, {
    width:     is_mobile ? '100%' : 'auto',
    height:    is_mobile ? '100%' : 'auto',
    autoSize:  !is_mobile,
    scrolling: 'hidden',
    helpers:   {
      overlay: {
        locked: true
      }
    },
    beforeShow: function () {
      ScrollLock.on();
    },
    afterClose: function () {
      ScrollLock.off();
    },
    padding: 0,
    margin:  [10, 10, 10, 10],
    tpl:     {
      wrap:
        '<div class="fancybox-next-opening fancybox-wrap auto-pop-up-fancy" tabIndex="-1"><div class="fancybox-skin"><div class="fancybox-outer"><div class="fancybox-inner fancybox-slide"></div></div></div></div>'
    }
  });
}
