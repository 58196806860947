
function addListenerShowMoreBtn() {
  let showMoreOfferNotificationsBtn = document.querySelector('.js-show-more-offer-notification');
  if (showMoreOfferNotificationsBtn) {
    showMoreOfferNotificationsBtn.addEventListener('click', () => {
      showMoreOfferNotificationsBtn.classList.add('hidden');
      let hiddenNotification = document.querySelectorAll('.offer-notification-container');
      hiddenNotification.forEach(item => {
        item.classList.remove('hidden');
      });
    });
  }
}

function init() {
  return function () {
    hideNotificationIcon();
    addListenerShowMoreBtn();
  };
}

function hideNotificationIcon() {
  iconContainer = getMenuItem();
  iconBtn = document.querySelector('.all-offer-notifications-container');
  if (iconContainer && iconBtn) {
    iconContainer.style.display = 'none';
  }
}

function getMenuItem() {
  if (IB.currentDevice === 'desktop') {
    return document.querySelector('.for-main-nav .user-notifications');
  }
  if (IB.currentDevice === 'tablet') {
    return document.querySelector('.for-user-nav .user-notifications');
  }
  return document.querySelector('.mobile-user.user-notifications');
}

window.IB.offer_notifications = {
  init: init,
};

$(function () {
  let initFunction = IB.offer_notifications.init();

  if (IB.browsing_sessions != null) {
    if (IB.browsing_sessions.getData() !== null) {
      initFunction();
    } else {
      IB.browsing_sessions.readRedis(initFunction);
    }
  }
});
