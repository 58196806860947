import { currentDevice } from '../../core/utils/currentDevice'

import { initializeUtagEvents } from './hotel_information_ratings.analytics'

function init() {
  const showMoreBtn = document.querySelectorAll('.js-awards-show-more')
  initializeUtagEvents(showMoreBtn)

  showMoreBtn.forEach(btn => {
    btn.addEventListener('click', event => {
      event.preventDefault()
      event.stopPropagation()
      const target = event.target
      const awardsList = target.closest('.awards-list')
      const hiddenItems = awardsList.querySelectorAll('.award-item.hidden')

      hiddenItems.forEach((item, index) => {
        if (index < 3) {
          item.classList.remove('hidden')
        }
      })
      if (hiddenItems.length < 3) {
        target.classList.add('hidden')
      }
    })
  })
}

if (currentDevice.isMobile || currentDevice.isLikeMobile) {
  init()
}
