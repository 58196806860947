import './alert.scss';

export function init() {
  if ($('.flash.fixed').length) {
    $('body').append($('.flash.fixed').addClass('active'));
    setTimeout((function () {
      $('.flash.fixed').css('transform', 'translateY(-100%)');
    }), 5000);
  }
}

$(document).on('click', '.alert .close', function (event) {
  event.preventDefault();
  let $this = $(this);
  let $flash = $this.closest('.wrapper.flash');
  $this.closest('.alert').remove();
  if (!$flash.find('.alert').length) $flash.remove();
});

init();
