import { sendUtagEvent } from '../../core/analytics/utag_events'
import on from '../../utils/events_delegation'
import { currentPageType } from '../../utils/page_type_detector'
import { getCleanedString } from '../../utils/utilities'

export function initializeUtagEvents(parentSelector) {
  on({
    eventName: 'click',
    selector: `${parentSelector} .utag-offer-modal-go-to-campaign`,
    handler() {
      const utagEec = JSON.parse(this.dataset.utagEec)

      sendUtagEvent({
        data: {
          enh_action: 'promo_click',
          event_name: 'select_promotion',
          event_cat: 'ecommerce',
          event_act: 'promo click',
          event_lbl: getCleanedString(utagEec.promotion_name),
          event_purpose: 'booking',
          event_structure: 'popup',
          promotion_id: [utagEec.promotion_id],
          promotion_name: [utagEec.promotion_name],
          promotion_creative: [utagEec.promotion_creative],
          promotion_position: [utagEec.promotion_position],
        },
      })

      if (currentPageType.isDestination()) {
        sendUtagEvent({
          data: {
            event_name: 'offers',
            event_cat: 'offers',
            event_act: 'view offers conditions',
            event_lbl: '',
            event_purpose: 'information',
            event_structure: 'popup',
          },
        })
      }
    },
  })

  if (currentPageType.isDestination()) {
    on({
      eventName: 'click',
      selector: `${parentSelector} .utag-offer-modal-show-hotels`,
      handler() {
        sendUtagEvent({
          data: {
            event_name: 'offers',
            event_cat: 'offers',
            event_act: 'view hotels',
            event_lbl: '',
            event_purpose: 'inspiration',
            event_structure: 'popup',
          },
        })
      },
    })
  }
}
