/* eslint-disable no-jquery/no-data */
/* eslint-disable no-jquery/no-jquery-constructor */
/* eslint-disable no-jquery/no-other-methods */
/* eslint-disable no-jquery/no-each-collection */
/* eslint-disable no-jquery/no-attr */
/*no*/
import '../../blocks/register_form/register_form'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { currentDevice } from '../../core/utils/currentDevice'
import Modal from '../../shared/modal/modal'

dayjs.extend(customParseFormat)
;(function () {
  // Variable to store date field intersection status
  let isIntersecting = false

  if (typeof IB === 'undefined') {
    window.IB = {}
  } else if (typeof IB.forms === 'undefined') {
    IB.forms = {}
  } else if (typeof IB.forms.register_form !== 'undefined') {
    return
  }

  function init() {
    const $birth_date = $('.single-datepicker-birth-date')
    if ($birth_date.length > 0) {
      const language = document.documentElement.getAttribute('data-js-lang')
      const date_format = $('html').attr('data-date-format')
      const start_of_week = $('html').attr('data-start-of-week')

      $birth_date.each(function (index, el) {
        if ($(el).data('dateRangePicker')) {
          $(el).data('dateRangePicker').destroy()
        }
        $(el)
          .dateRangePicker({
            autoClose: true,
            format: date_format,
            startOfWeek: start_of_week,
            language,
            showDropdowns: true,
            startDate: new dayjs().subtract(100, 'years').format(date_format),
            endDate: new dayjs().subtract(18, 'years').format(date_format),
            minYear: new dayjs().year() - 100,
            maxYear: new dayjs().year() - 18,
            defaultTime: new dayjs().subtract(18, 'years').format(date_format),
            singleDate: true,
            singleMonth: true,
            selectBackward: true,
            showTopbar: false,
          })
          .on('datepicker-open', function () {
            // Lock Scroll on datepicker open only in mobile
            currentDevice.isMobile && ScrollLock.on()
            const offset_left = $(el).offset().left
            $('.date-picker-wrapper').css('left', offset_left)
            // When the calendar is opened, set this variable to true to prevent
            // the datepicker from closing on scroll until the intersection observer
            // detects a new intersection between both elements
            isIntersecting = true
            if ($(el).val() === '') {
              const date = new dayjs().subtract(18, 'years').format(date_format)
              $(el).data('dateRangePicker').setDateRange(date, date)
            }
          })
          .on('datepicker-opened', function (ev, picker) {
            $(this).prop('readonly', true)
          })
          .on('datepicker-first-date-selected', function (ev, picker) {
            $(this).prop('readonly', false)
            $(this).val(dayjs(picker.time).format(date_format)).trigger('change')
            $(el).closest('form').parsley().validate('datepicker')
          })
          .on('datepicker-close', function () {
            // Unlock Scroll on datepicker close only in mobile
            currentDevice.isMobile && ScrollLock.off()
          })

        $(el).on('focus', function () {
          $(el).data('dateRangePicker').open()
        })

        // PY04776-533 - 761157 - Calendario de registro se descoloca
        // Fix bug that if the postregister modal is closed and the data picker is open, the data picker closes as well.
        $('[data-fancybox-close]').on('click', function () {
          $(el).data('dateRangePicker').close()
        })
      })
    }

    // Depending on the device type, the observer should be fired differently.
    // Add .register class for the register form in star agents
    const PARENT_SCROLL_SELECTOR = `${
      currentDevice.isMobile ? '.js-login-registration-lightbox' : '.session-tab-registration'
    }, .second-layer.register`

    // Initialize the observer
    var observer = new IntersectionObserver(
      entries => {
        isIntersecting = entries[0].isIntersecting
      },
      {
        root: null,
        threshold: 1,
        rootMargin: '0px 0px -280px 0px',
      }
    )

    // Listen for scroll in the register form.
    // Depending on the device type, the scroll event will be fired differently.
    if ($birth_date[0] !== undefined) {
      // Set the element to observe
      observer.observe(document.querySelector('.single-datepicker-birth-date'))
      // Lister for scroll to calculate the position of the calendar or close it
      $birth_date[0].closest(PARENT_SCROLL_SELECTOR)?.addEventListener('scroll', e => {
        if (isIntersecting) {
          // While the field is visible, change the position of the calendar.
          $birth_date.data('dateRangePicker').calcPosition()
        } else {
          // If the field is not visible, close the calendar.
          $birth_date.data('dateRangePicker').close()
        }
      })
    }

    // Modal con vídeo para el formulario de registro de Iberostar PRO
    // --> PY04777-688: 747192 - Tutorial Alta en registro de alta
    const videoLink = document.querySelector('.js-video-module')
    const videoPlay = document.querySelector('.modal-play')

    videoLink?.addEventListener('click', function (e) {
      e.preventDefault()
      Modal.show('video-tutorial-register', {
        onShow: () => {
          videoPlay.play()
        },
        onClose: () => {
          videoPlay.pause()
        },
        awaitCloseAnimation: true,
      })
    })
  }

  window.IB.forms.register_form = {
    init,
  }

  // Inialize the register form only in the iberostar pro microsite
  const userRegisterForm = document.querySelector('.form-users')
  if (IB.current_microsite === 'iberostar_pro' && userRegisterForm) {
    init()
  }
})()
