import { getUserLevel } from '../../core/iberapi/browsing_session/browsing_session'
import initPromoLink from '../../shared/promo_link/promo_link'
import countdownClock from '../../utils/countdown'

import { sendHeadbandUtagEvent } from './headband.analytics'

const ACTIVE_STICKY_HEADBANDS_CLASS = '.js-headband.sticky.slide-down'
const visibleHeadbands = []
const AFFECTED_ELEMENTS_BY_STICKY_HEADBANDS = [
  'js-reb-header',
  'js-fastbooking-home',
  'js-mobile-header',
  'js-vue-mobile-menu',
  'js-header-sticky-hotel',
]

function showHeadbandCountdown(headband) {
  const now = new Date()
  const countdownStart = new Date(headband.dataset.countdownStart)
  const countdownEnd = new Date(headband.dataset.countdownEnd)
  const countdown = headband.querySelector('.js-countdown')
  const expiredText = JSON.parse(countdown.dataset.translations).expired

  if (now >= countdownStart) {
    countdown.classList.remove('hidden')
    countdownClock(1000, 9999)

    if (expiredText === '' && now >= countdownEnd) {
      countdown.remove()
    }
  }
}

function cleanUnusedHeadbands() {
  document.querySelectorAll('.js-headband:not(.slide-down)').forEach(hiddenHeadband => {
    hiddenHeadband.remove()
  })
}

function isHeadbandInTime(headband) {
  const now = new Date()
  const start = new Date(headband.dataset.start)
  const end = new Date(headband.dataset.end)

  if (now >= start && now <= end) {
    return true
  }
}

function slideDownHeadband(headband, callback) {
  headband.classList.add('slide-down')

  headband.addEventListener('transitionend', callback, { once: true })
}

async function loopHeadbands(headbands) {
  const userLevel = getUserLevel()

  for (const headband of headbands) {
    const allowedUserLevels = JSON.parse(headband.dataset.loggedInLevels)
    const isVisibleToUser = allowedUserLevels.includes(userLevel)
    const unlockMetrics = headband.dataset.metrics

    if (isVisibleToUser && isHeadbandInTime(headband)) {
      showHeadbandCountdown(headband)
      slideDownHeadband(headband)
      headband.classList.remove('headband__stash')
      setTimeout(function () {
        headband.classList.remove('headband__stash')
      }, 100)

      const promoLinkElement = headband.querySelector('.js-promolink')

      if (unlockMetrics === 'true') {
        promoLinkElement.addEventListener('click', function () {
          sendHeadbandUtagEvent(headband)
        })
      }

      initPromoLink(promoLinkElement)
      visibleHeadbands.push(headband)
    }
  }
}

function addStickyStateClassToAffectedElements() {
  AFFECTED_ELEMENTS_BY_STICKY_HEADBANDS.forEach(affectedElement => {
    const elements = document.querySelectorAll(`.${affectedElement}`)

    if (!elements) return

    elements.forEach(element => {
      element.classList.add('pushed-by-sticky-headbands')
    })
  })
}

function pushBodyWhenHeadbandAreSticky(occupiedHeight) {
  document.body.style.transition = 'all 1s ease-in-out'
  document.body.style.paddingTop = `${occupiedHeight}px`
  document.body.style.position = 'relative'
}

function setStickyHeadbandsPositions() {
  const stickyHeadbands = document.querySelectorAll(ACTIVE_STICKY_HEADBANDS_CLASS)

  stickyHeadbands.forEach((headband, index) => {
    const previousHeadband = stickyHeadbands[index - 1]
    if (previousHeadband) {
      headband.style.top = `${previousHeadband.clientHeight}px`
    }
  })
}

function addPushedByStickyHeadbandsClassToBody(occupiedHeight) {
  const style = document.createElement('style')
  style.innerHTML = `
    .sticky.pushed-by-sticky-headbands {
      top: ${occupiedHeight}px !important;
    }
  `
  document.body.appendChild(style)
}

function getTotalHeightOfStickyHeadbands() {
  const stickyHeadbands = document.querySelectorAll(ACTIVE_STICKY_HEADBANDS_CLASS)
  const stickyHeadbandsTotalHeight = Array.from(stickyHeadbands).reduce(
    (total, stickyHeadband) => total + stickyHeadband.clientHeight,
    0
  )
  return stickyHeadbandsTotalHeight - 2 // quit 2px to avoid gaps
}

export default function init() {
  const headbands = document.querySelectorAll('.js-headband')

  if (!headbands) return

  loopHeadbands(headbands)
  cleanUnusedHeadbands()

  const headband = Array.from(visibleHeadbands).find(headband => headband.classList.contains('sticky'))
  if (headband) {
    setStickyHeadbandsPositions()
    addStickyStateClassToAffectedElements()
    slideDownHeadband(headband, () => {
      const occupiedHeight = getTotalHeightOfStickyHeadbands()
      addPushedByStickyHeadbandsClassToBody(occupiedHeight)
      pushBodyWhenHeadbandAreSticky(occupiedHeight)
    })
  }
}

document.addEventListener('DOMContentLoaded', () => {
  requestIdleCallback(init)
})
