$(document).on('click', '.cookies-content .close-fancybox', function () {
  $('.fancybox-close-small')[0].click();
  $('#cookies-config')[0].click();
});

$(document).on('click', '#agree_cookies_in_cookies_lightbox_floating, #agree_cookies_in_cookies_lightbox', function () {
  $('.fancybox-close-small')[0].click();
  $('#agree-cookies')[0].click();
});

function toggleFaq(event) {
  if(event.target && event.target.parentNode && event.target.parentNode.classList){
    if (event.target.parentNode.classList.contains('collapsible-blocks')) {
      let content = event.target.nextElementSibling;

      event.target.classList.toggle('active');

      if (content.style.display === 'block') {
        content.style.display = 'none';
      } else {
        content.style.display = 'block';
      }
    }
  }
}

document.addEventListener('click', toggleFaq);

