import { getLazyData } from '../../utils/get_lazy_data';
import { getPrefix } from '../../core/utils/locale.js';

if($("body").hasClass("show-smartvel") === true){
  let URL_FOR_SMARTVEL = getPrefix() === "/es" ? "/show-smartvel/" : getPrefix()+"/show-smartvel/";
  let URL_PARAMS = window.location.search.substring(1);
  if(URL_PARAMS !== ""){
    URL_FOR_SMARTVEL = URL_FOR_SMARTVEL+"?"+URL_PARAMS;
  }
  load_smartvel_in_fancybox(URL_FOR_SMARTVEL);
}

$(document).on("click",".destination-guide", function(e){
  let URL_FOR_SMARTVEL = getPrefix() === "/es" ? "/show-smartvel/" : getPrefix()+"/show-smartvel/";
  e.preventDefault();
  let $el = $(this);
  let data = $el.data();
  let URL_PARAMS = "destino="+data.destino+"&entrada="+data.entrada+"&salida="+data.salida+"&idioma="+data.idioma+"&nivel="+data.nivel;
  URL_FOR_SMARTVEL = URL_FOR_SMARTVEL+"?"+URL_PARAMS;
  load_smartvel_in_fancybox(URL_FOR_SMARTVEL);
})

function load_smartvel_in_fancybox(url) {
  getLazyData(url, function (lightboxHtml) {
    $.fancybox.open(lightboxHtml,{ baseClass: "smartvel-fancybox"});
  });
}
