import { sendUtagEvent } from '../../core/analytics/utag_events'

const BLOCK_SELECTOR = '.js-header-customized'

function handleElementPageOfferViewConditions(buttonCta) {
  buttonCta.addEventListener('click', function () {
    sendUtagEvent({
      data: {
        event_name: 'offers',
        event_cat: 'offers',
        event_act: 'view offers conditions',
        event_lbl: '',
        event_purpose: 'information',
        event_structure: 'header',
      },
    })
  })
}

function handleElementPageOfferViewHotels(elementCta) {
  elementCta.addEventListener('click', function () {
    sendUtagEvent({
      data: {
        event_name: 'offers',
        event_cat: 'offers',
        event_act: 'view hotels',
        event_lbl: '',
        event_purpose: 'inspiration',
        event_structure: 'header',
      },
    })
  })
}

export function initializeUtagEvents(blockSelector) {
  const block = document.querySelector(blockSelector)

  if (!block) return

  const elementPageOfferTitleViewConditions = document.querySelector(
    `${BLOCK_SELECTOR} #id-offer-page-title-view-conditions`
  )

  const elementPageOfferTitleViewHotels = document.querySelector(`${BLOCK_SELECTOR} #id-offer-page-title-view_hotels`)

  if (elementPageOfferTitleViewConditions) {
    handleElementPageOfferViewConditions(elementPageOfferTitleViewConditions)
  }

  if (elementPageOfferTitleViewHotels) {
    handleElementPageOfferViewHotels(elementPageOfferTitleViewHotels)
  }

  if (block.classList.contains('js-header-with-offer')) {
    const elementPageOfferCtaViewConditions = document.querySelector(`${BLOCK_SELECTOR} .js-offer-cta-show-conditions`)
    const elementPageOfferCtaViewHotels = document.querySelector(`${BLOCK_SELECTOR} .js-offer-cta-show-hotels`)

    if (elementPageOfferCtaViewConditions) {
      handleElementPageOfferViewConditions(elementPageOfferCtaViewConditions)
    }

    if (elementPageOfferCtaViewHotels) {
      handleElementPageOfferViewHotels(elementPageOfferCtaViewHotels)
    }
  }
}
