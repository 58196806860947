/**
 * @param {number} intervalTimer - Refresh interval in milliseconds
 * @param {number} maxDays - Max days who can have the countdown
 * @return {void}
 * @example countdown(1000, 7);
 * ---
 * The referenced tag should contain:
 * data-countdown with the end date and correct date format, example: data-countdown="2022-10-13T16:26:00+00:00"
 * data-display-type with the value "simple" or undefined to show the countdown with the format: 1d 23h 59m
 * data-translations with the translations for the countdown, example: data-translations='{"expired":"Expired","days":"d","hours":"h","minutes":"m","seconds":"s"}'
 */
function countdown(intervalTimer = 1000, maxDays = 7) {
  const COUNTDOWN_SELECTOR = '.js-countdown';
  let countdowns = document.querySelectorAll(COUNTDOWN_SELECTOR);

  for (let index = 0; index < countdowns.length; index++) {

    // Target countdown
    let countdown = countdowns[index];
    let offer = [];
    let diff = [];
    let countDownDate = [];

    offer[index] = countdowns[index].dataset.countdown;
    // If no countdown present, don't render countdown
    if (offer[index]) {
      offer[index].replaceAll(/\b[A-Z]+\b/g, '');
      countDownDate[index] = offer[index] !== '' ? new Date(offer[index].toString()).getTime() : "";

      //First execution so there is no initial delay and we can set a longer intervalTimer
      renderCountdown(countdown, countDownDate, diff, index, maxDays);
      // Countdown
      let timer = setInterval(function () {
        renderCountdown(countdown, countDownDate, diff, index, maxDays);

        // If the count down is over, write some advice
        if (diff[index] < 0) {
          clearInterval(timer);
        }

        //--------------------------------------------------------------------------------//

        // Reset countdown on apply filters
        document.addEventListener('appliedFilterResults', function () {
          clearInterval(timer);
        });
        // Reset countdown on change pagination
        document.addEventListener('changedPaginationEvent', function () {
          clearInterval(timer);
        });
        // Reset countdown on chage order results
        document.addEventListener('orderResultsEvent', function () {
          clearInterval(timer);
        });

        //--------------------------------------------------------------------------------//

      }, (intervalTimer));
    }
  }
}

function renderCountdown(countdown, countDownDate, diff, index, maxDays) {
  // get today's date
  const today = new Date().getTime();

  // get the difference
  diff[index] = countDownDate[index] - today;

  // If the count down is over, write some advice
  if (diff[index] < 0 && countdown.parentElement) {
    countdown.parentElement.innerHTML = JSON.parse(countdown.dataset.translations).expired;
    return;
  }

  // math
  let days = Math.floor(diff[index] / (1000 * 60 * 60 * 24));
  let hours = Math.floor((diff[index] % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let minutes = Math.floor((diff[index] % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = Math.floor((diff[index] % (1000 * 60)) / 1000);

  if (days <= maxDays && diff[index] > 0) {
    // display
    if (countdown) {
      let displayType = countdown.dataset.displayType;
      let textToDisplay;

      var days_text = JSON.parse(countdown.dataset.translations).days;
      var hours_text = JSON.parse(countdown.dataset.translations).hours;
      var minutes_text = JSON.parse(countdown.dataset.translations).minutes;

      var seconds_config = JSON.parse(countdown.dataset.translations).seconds;

      if (displayType == 'simple') {
        textToDisplay = `${days}${days_text} ${hours}${hours_text} ${minutes}${minutes_text}`;
      } else {
        textToDisplay = `${days} ${days_text} ${hours} ${hours_text} ${minutes} ${minutes_text}`;
      }

      countdown.innerHTML = textToDisplay + " " + (seconds_config != undefined ? (seconds + seconds_config) : "");
    }
  } else {
    if (days > maxDays) {
      if (countdown) {
        countdown.classList.add('is-hidden');
        if (countdown.parentElement.classList.contains('js-special-offer-counter')) {
          countdown.parentElement.classList.add('is-hidden')
        }
      }
    }
  }
}

export default countdown;
