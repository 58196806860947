/**!
 * Returns true if given video is playing
 * @param { DomElement } video video DOM element
 */

export const isVideoPlaying = video =>
  !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)

export const addSourcesToVideosFromDataAttributes = videos => {
  if (!videos.length) return

  videos.forEach(video => {
    if (video.querySelector('source')) return // Sources already loaded
    let videoSources = video.dataset.mp4Src ? `<source src="${video.dataset.mp4Src}" type="video/mp4">` : ''
    videoSources += video.dataset.webmSrc ? `<source src="${video.dataset.webmSrc}" type="video/webm">` : ''
    video.innerHTML += videoSources
  })
}
