import { currentDevice } from '../../core/utils/currentDevice'
import Modal from '../../shared/modal/modal'

const trigger_selector = '.js-side-panel-trigger'
const close_selector = '.side_panel__close'
const book_now_selector = '.btn-floating-fastbooking'
const data_selector = '.js-side-panel-data'

function init() {
  const showModal = (e, modalId) => {
    Modal.show(modalId, {
      onShow: modal => {
        modal.classList.add('fancybox-is-open')
        ScrollLock.on()
      },
      onClose: modal => {
        modal.classList.remove('fancybox-is-open')
        ScrollLock.off()
      },
      awaitCloseAnimation: true,
    })
  }

  document.addEventListener('click', event => {
    const target = event.target
    if (target.matches(trigger_selector)) {
      const data = target.dataset
      const sidePanel = document.querySelector(`.side_panel#${data.sidePanelId}`)
      if (sidePanel.classList.contains('has_iframe')) {
        let dataIFrame = target.querySelector(data_selector)
        if (dataIFrame === null) {
          dataIFrame = target
        }
        const iframe = sidePanel.querySelector('.side_panel__iframe')
        iframe?.setAttribute('src', dataIFrame.getAttribute('href'))
      }
      showModal(event, data.sidePanelId)
    }

    if (target.matches(data_selector)) {
      target.closest(trigger_selector).click()
    }
  })

  const closeButtons = document.querySelectorAll(close_selector)
  closeButtons.forEach(button => {
    button.addEventListener(
      'click',
      event => {
        event.preventDefault()
        event.stopPropagation()
        Modal.close()
      },
      true
    )
  })

  const bookButtons = document.querySelectorAll(book_now_selector)
  bookButtons.forEach(button => {
    button.addEventListener('click', () => {
      // no se para el evento porque sino no se abre fb
      Modal.close()
    })
  })
}

// iniciar el panel lateral solo en mobile
if (currentDevice.isMobile) {
  init()
}
