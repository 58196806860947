import { checkloadComponentStyles } from '../../src/lazy_wrappers/lazy_block_lightbox'

export function openSessionFancybox(fancyboxHtml, formSelector, userData, onlyRegister) {
  $.fancybox.open(fancyboxHtml, {
    trapFocus: false,
    afterShow: () => {
      IB.deviceDetector.loadDeviceDataToForm()
      checkloadComponentStyles()
    },
  })
  if (formSelector == '.login form') {
    document.querySelector('[data-tab=".js-tab-wrapper#session-tab-login"]').classList.add('active')
    document.querySelector('[data-tab=".js-tab-wrapper#session-tab-registration"]').classList.remove('active')
    if (document.getElementById('session-tab-login')) {
      document.getElementById('session-tab-login').classList.add('active')
    }
    document.getElementById('session-tab-registration').classList.remove('active')
    IB.forms.initValidation($(formSelector))
  } else {
    if (document.querySelector('[data-tab=".js-tab-wrapper#session-tab-login"]')) {
      document.querySelector('[data-tab=".js-tab-wrapper#session-tab-login"]').classList.remove('active')
    }
    if (document.querySelector('[data-tab=".js-tab-wrapper#session-tab-registration"]')) {
      document.querySelector('[data-tab=".js-tab-wrapper#session-tab-registration"]').classList.add('active')
    }
    if (document.getElementById('session-tab-login')) {
      document.getElementById('session-tab-login').classList.remove('active')
    }
    if (document.getElementById('session-tab-registration')) {
      document.getElementById('session-tab-registration').classList.add('active')
    }
    IB.forms.initValidation($(formSelector))
  }

  if (onlyRegister) {
    if (document.getElementsByClassName('cabecera').length > 0) {
      document.getElementsByClassName('cabecera')[0].remove()
    }
    if (document.getElementById('session-tab-login')) {
      document.getElementById('session-tab-login').remove()
    }
  }

  IB.forms.register_form.init()
  IB.formUtagEvents.init()
  const $form = $('.js-login-form form')
  const $required_fields = $form.find('[data-parsley-required]:not([data-parsley-errors-container])')
  const parsley_config = {
    errorsContainer: pe => {
      const $err = pe.$element.closest('.fz-col').find('.fz-error-container')
      return $err
    },
  }

  FORMZ.required_fields.init($required_fields)
  $form.parsley(parsley_config)
}
