import { setCookie } from '../../utils/cookie_manager';

function autoPopUpOpen() {
  let $auto_pop_up = $('#auto-pop-up');
  let is_mobile = IB.currentDevice === 'mobile';
  let bg = is_mobile ? $auto_pop_up.data('mobile') : $auto_pop_up.data('desktop');

  if (!$auto_pop_up.length) return;

  if (bg !== '' && bg !== undefined) {
    $auto_pop_up.css({
      'background-image': 'url(' + bg + ')'
    });
  }
  $.fancybox.open($auto_pop_up, {
    afterClose: function () {
      setCookie('seen_auto_pop_up', 'true', 30);
    }
  });
}


$(function () {
  if ($('#auto-pop-up').length) {
    if (typeof $.cookie('seen_auto_pop_up') === 'undefined') {
      autoPopUpOpen();
    }
  }
});
