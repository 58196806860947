function initDestinationBreadcrumbs() {
  $('.destination-breadcrumb .destination-list .wrapper').each(function () {
    let $wrapper = $(this);
    $wrapper.width($wrapper.siblings('.d-link').width());
  });
  $(document).on('click', '.destination-breadcrumb .d-link', function (e) {
    e.preventDefault();
    let $target = $(this).closest('.d-link');

    calculateChildrenPosition($target);

    if (!$target.hasClass('active')) {
      let $prev_active = $('.destination-breadcrumb .d-link.active');

      $($prev_active.attr('data-target')).hide();
      $prev_active.removeClass('active');
    }

    $($target.attr('data-target')).slideToggle('fast');
    $target.toggleClass('active');
  });

  $('.destination-breadcrumb .destination-list').scroll(function () {
    let $active_dropdowns = $(this).find('.d-link.active');
    if ($active_dropdowns.length) {
      calculateChildrenPosition($active_dropdowns);
    }
  });
}

function calculateChildrenPosition($target) {
  let $submenuWrapper = $target.closest('.has-children').find('.wrapper');
  let menuItemPos = $target.position();

  $submenuWrapper.css({
    top:  menuItemPos.top + $target.outerHeight(),
    left: menuItemPos.left
  });
}

initDestinationBreadcrumbs();
